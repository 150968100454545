import React from "react";
import theme from "../../theme.scss";

export const ArrowCircleRightIcon = ({ width, colour }) => {
  return (
    <svg
      width={width || 20}
      height={width || 20}
      viewBox="0 0 20 20"
      fill="none"
      id="ArrowCircleRightIcon"
    >
      <path
        d="M9.99996 13.3334L13.3333 10.0001L9.99996 6.66675L8.83329 7.83341L10.1666 9.16675H6.66663V10.8334H10.1666L8.83329 12.1667L9.99996 13.3334ZM9.99996 18.3334C8.84718 18.3334 7.76385 18.1147 6.74996 17.6772C5.73607 17.2397 4.85413 16.6459 4.10413 15.8959C3.35413 15.1459 2.76038 14.264 2.32288 13.2501C1.88538 12.2362 1.66663 11.1529 1.66663 10.0001C1.66663 8.8473 1.88538 7.76397 2.32288 6.75008C2.76038 5.73619 3.35413 4.85425 4.10413 4.10425C4.85413 3.35425 5.73607 2.7605 6.74996 2.323C7.76385 1.8855 8.84718 1.66675 9.99996 1.66675C11.1527 1.66675 12.2361 1.8855 13.25 2.323C14.2638 2.7605 15.1458 3.35425 15.8958 4.10425C16.6458 4.85425 17.2395 5.73619 17.677 6.75008C18.1145 7.76397 18.3333 8.8473 18.3333 10.0001C18.3333 11.1529 18.1145 12.2362 17.677 13.2501C17.2395 14.264 16.6458 15.1459 15.8958 15.8959C15.1458 16.6459 14.2638 17.2397 13.25 17.6772C12.2361 18.1147 11.1527 18.3334 9.99996 18.3334ZM9.99996 16.6667C11.8611 16.6667 13.4375 16.0209 14.7291 14.7292C16.0208 13.4376 16.6666 11.8612 16.6666 10.0001C16.6666 8.13897 16.0208 6.56258 14.7291 5.27091C13.4375 3.97925 11.8611 3.33341 9.99996 3.33341C8.13885 3.33341 6.56246 3.97925 5.27079 5.27091C3.97913 6.56258 3.33329 8.13897 3.33329 10.0001C3.33329 11.8612 3.97913 13.4376 5.27079 14.7292C6.56246 16.0209 8.13885 16.6667 9.99996 16.6667Z"
        fill={colour || theme.primary6}
      />
    </svg>
  );
};
