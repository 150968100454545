import React from "react";
import theme from "../../theme.scss";

export const BedIcon = ({ width, colour }) => {
  return (
    <svg
      width={width || 24}
      height={width || 24}
      viewBox="0 0 24 24"
      fill="none"
      id="BedIcon"
    >
      <path
        d="M2 19V13C2 12.55 2.09167 12.1417 2.275 11.775C2.45833 11.4083 2.7 11.0833 3 10.8V8C3 7.16667 3.29167 6.45833 3.875 5.875C4.45833 5.29167 5.16667 5 6 5H10C10.3833 5 10.7417 5.07083 11.075 5.2125C11.4083 5.35417 11.7167 5.55 12 5.8C12.2833 5.55 12.5917 5.35417 12.925 5.2125C13.2583 5.07083 13.6167 5 14 5H18C18.8333 5 19.5417 5.29167 20.125 5.875C20.7083 6.45833 21 7.16667 21 8V10.8C21.3 11.0833 21.5417 11.4083 21.725 11.775C21.9083 12.1417 22 12.55 22 13V19H20V17H4V19H2ZM13 10H19V8C19 7.71667 18.9042 7.47917 18.7125 7.2875C18.5208 7.09583 18.2833 7 18 7H14C13.7167 7 13.4792 7.09583 13.2875 7.2875C13.0958 7.47917 13 7.71667 13 8V10ZM5 10H11V8C11 7.71667 10.9042 7.47917 10.7125 7.2875C10.5208 7.09583 10.2833 7 10 7H6C5.71667 7 5.47917 7.09583 5.2875 7.2875C5.09583 7.47917 5 7.71667 5 8V10ZM4 15H20V13C20 12.7167 19.9042 12.4792 19.7125 12.2875C19.5208 12.0958 19.2833 12 19 12H5C4.71667 12 4.47917 12.0958 4.2875 12.2875C4.09583 12.4792 4 12.7167 4 13V15Z"
        fill={colour || theme.neutral7}
      />
    </svg>
  );
};
