import React from "react";
import theme from "../../theme.scss";

export const FallIcon = ({ width, color }) => {
  return (
    <svg
      height={width || "24"}
      width={width || "24"}
      viewBox="0 0 24 24"
      id="FallIcon"
    >
      <path
        d="M13.5 22.5L11.5 22.1L12.3 17.8L8.7 15.1L7.4 9.4L5.2 11.3L6 15.1L4 15.5L3 10.6L7.45 6.625C7.83333 6.29167 8.2875 6.15417 8.8125 6.2125C9.3375 6.27083 9.84167 6.4 10.325 6.6C10.8583 6.83333 11.4125 7 11.9875 7.1C12.5625 7.2 13.1417 7.20833 13.725 7.125C14.3083 7.04167 14.8458 6.85 15.3375 6.55C15.8292 6.25 16.3 5.91667 16.75 5.55L18 7.1C17.5 7.48333 16.9833 7.84167 16.45 8.175C15.9167 8.50833 15.3417 8.76667 14.725 8.95C14.175 9.1 13.6208 9.17917 13.0625 9.1875C12.5042 9.19583 11.95 9.13333 11.4 9L12.1 12.1L15.8 11.4L21 15.1L19.8 16.7L15.5 13.7L11.9 14.4L14.6 16.4L13.5 22.5ZM8 5.5C7.45 5.5 6.97917 5.30417 6.5875 4.9125C6.19583 4.52083 6 4.05 6 3.5C6 2.95 6.19583 2.47917 6.5875 2.0875C6.97917 1.69583 7.45 1.5 8 1.5C8.55 1.5 9.02083 1.69583 9.4125 2.0875C9.80417 2.47917 10 2.95 10 3.5C10 4.05 9.80417 4.52083 9.4125 4.9125C9.02083 5.30417 8.55 5.5 8 5.5Z"
        fill={color || theme.neutral7}
      />
    </svg>
  );
};
