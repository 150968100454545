import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import styles from "./styles.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
// Utils
import { getInitialsFromNames } from "@intelligentlilli/lilli-utils";
// API
import { postLogout } from "@intelligentlilli/api-layer";
import {
  postUpdatePassword,
  postUpdateProperty,
  getOrganisation,
  postUserEmail,
} from "@intelligentlilli/api-layer";
// Components
import Page from "../../Components/Page";
import CTAButton from "../../Components/CTAButton";
import { useViewport } from "../../Components/ViewportProvider";
import PrimaryButton from "../../Components/PrimaryButton";
import ServiceUserImage from "../../Components/ServiceUserImage";
import EditUserProfile from "../../Components/EditUserProfile";
import SecondaryButton from "../../Components/SecondaryButton";
import PageTitle from "../../Components/PageTitle";
// State
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setRefreshUser } from "../../State/slices/session-slice";
import { setStaff } from "../../State/slices/staff-slice";
import { setServiceUsersData } from "../../State/slices/serviceUsersData-slice";
import { setUser } from "../../State/slices/user-slice";
import { setServiceUsers } from "../../State/slices/serviceUsers-slice";
import { mobileBreakpoint } from "../../Services/config";
// Icons
import { HomeIcon, PersonIcon, ReportsIcon } from "../../Styles/Icons";
// Hooks
import { useGetUserRole } from "../../Services/Hooks";

function Profile() {
  const navigate = useNavigate();
  const location = useLocation();
  // Get the width of the users screen for responsive js
  const { width } = useViewport();

  const isFalls = true; // TODO replace this with whatever flag we get from the API.

  // Get whether the user is coming from the onboarding screens
  const onboarding = location.state?.onboarding;
  // redux state
  const dispatch = useDispatch();
  const server = useSelector((state) => state.session.server);
  const user = useSelector((state) => state.user);
  // Local state to hold API error messages
  const [changePasswordError, setChangePasswordError] = useState(null);
  const [changeNameError, setChangeNameError] = useState(null);
  const [changeEmailError, setChangeEmailError] = useState(null);
  const [changePhoneNumError, setChangePhoneNumError] = useState(null);
  const [editProfile, setEditProfile] = useState(false);
  const [organisation, setOrganisation] = useState();

  // Get the user's role
  const { userIsJustAnInstaller, userHasInstallerRole, userRoleAsAString } =
    useGetUserRole();
  const displayUserRole = userIsJustAnInstaller
    ? "Installer"
    : `${userRoleAsAString}${userHasInstallerRole ? " & Installer" : ""}`;

  // Get the organisation details
  useEffect(() => {
    dispatch(setLoading(true));
    getOrganisation(server, user.organisationId, "web")
      .then((res) => {
        dispatch(setLoading(false));
        if (!res.ok) {
          if (res.status === 401) {
            navigate("/login");
          }
        } else {
          setOrganisation(res.body);
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  }, [dispatch, server, user.organisationId, navigate]);

  const onSubmitPasswordChange = async (currentPassword, newPassword) => {
    setChangePasswordError("");
    dispatch(setLoading(true));
    postUpdatePassword(server, currentPassword, newPassword, "web")
      .then((res) => {
        dispatch(setLoading(false));
        if (!res.ok) {
          if (res.status === 401) {
            navigate("/login");
          }
          if (res.status === 403) {
            setChangePasswordError("Sorry, we could not change your password.");
            alert("Sorry, we could not change your password.");
          }
          setChangePasswordError(res.body.error); // For some reason this is the format used here, different to the others
        } else {
          alert("Success: Password changed");
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log("Error with password change:", err);
      });
    return;
  };
  // Handle changing email address
  const onSubmitEmailChange = (email) => {
    setChangeEmailError("");
    dispatch(setLoading(true));
    postUserEmail(server, user.key, email, "web")
      .then((res) => {
        dispatch(setLoading(false));
        if (!res.ok) {
          setChangeEmailError(res.response.body.error); // This works - but different format to PW reset error
          if (res.status === 401) {
            navigate("/login");
          }
        } else {
          // Refresh the user so state represents the server again.
          dispatch(setRefreshUser(true));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log("Email change error:", err);
      });
    return;
  };

  // Handle changing user's names
  const onSubmitNameChange = async (forenames, surname) => {
    setChangeNameError("");
    const names = [
      { key: "Forenames", value: forenames },
      { key: "Surname", value: surname },
    ];
    const promises = names.map((name) =>
      postUpdateProperty(server, name.key, name.value, "web").then((res) => {
        if (res.status === 401) {
          navigate("/login");
        }
        if (!res.ok) {
          console.log("name change error:", res.response.body.error);
          setChangeNameError(res.response.body.error); // Have not been able to test if this is the format of the error.
        }
      })
    );
    await Promise.all(promises)
      .then(() => {
        // Refresh the user so state represents the server again.
        dispatch(setRefreshUser(true));
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        setChangeEmailError("Error changing name");
      });
    return;
  };

  // Handle logging out. When successful, redirect them to the login page.
  const handleLogout = async () => {
    dispatch(setLoading(true));
    try {
      const response = await postLogout(server, "web");
      if (!response.ok) {
        dispatch(setLoading(false));
        alert("Logout unsucessful");
      } else {
        // Resetting redux back to it's initial state on logout
        navigate("/login", { state: { initialURL: "/" } });
        dispatch(setUser(null));
        dispatch(setServiceUsersData({}));
        dispatch(setServiceUsers([]));
        dispatch(setLoading(false));
        dispatch(setStaff([]));
      }
    } catch (err) {
      // TODO send alert to user that they cannot be logged out
      dispatch(setLoading(false));
      console.log("logout error:", err);
    }
  };

  // Handle updating phone number
  const onSubmitPhoneNumChange = (value) => {
    setChangePhoneNumError("");
    dispatch(setLoading(true));
    postUpdateProperty(server, "PrimaryPhone", value, "web")
      .then((res) => {
        dispatch(setLoading(false));
        if (!res.ok) {
          setChangePhoneNumError(res.response.body.error);
          if (res.status === 401) {
            navigate("/login");
          }
        } else {
          // Refresh the user so state represents the server again.
          dispatch(setRefreshUser(true));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        alert("Error updating user preferences");
      });
  };

  return (
    <Page className={styles.page}>
      {onboarding ? (
        <PageTitle title="Welcome to Lilli" />
      ) : (
        <PageTitle title="Manage your account" />
      )}

      <div className={styles.content}>
        {!editProfile && (
          <>
            {onboarding && (
              <div className={styles.content_section}>
                <h2>
                  This is your profile page. Here you can change your details
                  and set your profile picture.
                </h2>
              </div>
            )}
            <div className={styles.content_section}>
              <div className={styles.profile}>
                <ServiceUserImage
                  initials={getInitialsFromNames(user.forenames, user.surname)}
                  staff
                  largePill
                  className={styles.profile_image}
                />
                <div className={styles.profile_name}>
                  <h2>
                    {user?.forenames} {user?.surname}
                  </h2>
                  <PrimaryButton
                    startIcon={<PersonIcon />}
                    onClick={() => setEditProfile((state) => !state)}
                    className={styles.profile_edit_mobile}
                    style={width > mobileBreakpoint ? { display: "none" } : {}}
                  >
                    Edit profile
                  </PrimaryButton>
                </div>

                <div>
                  Username: <b>{user?.identity}</b>
                </div>
                <div>
                  Roles: {displayUserRole}{" "}
                  {organisation?.name && `at ${organisation?.name}`}
                </div>
                <div>{user?.email}</div>
              </div>

              <PrimaryButton
                startIcon={<PersonIcon />}
                onClick={() => setEditProfile((state) => !state)}
                className={styles.profile_edit}
                style={width <= mobileBreakpoint ? { display: "none" } : {}}
              >
                Edit profile
              </PrimaryButton>
            </div>

            {onboarding && (
              <div className={styles.content_section}>
                <div className={styles.notifications}>
                  <h3>
                    Once you're happy head over to the Reports page to choose
                    your Notifications and Reports settings.
                  </h3>

                  <PrimaryButton
                    onClick={() => navigate("/reports")}
                    className={styles.notifications_button}
                    startIcon={<ReportsIcon />}
                  >
                    Reports
                  </PrimaryButton>
                </div>
              </div>
            )}
            {onboarding && (
              <div className={styles.content_section}>
                <div className={styles.notifications}>
                  <h3>
                    Want to get started straight away?. Head over to your
                    dashboard to see who you've been assigned to look after
                  </h3>

                  <PrimaryButton
                    onClick={() => navigate("/")}
                    className={styles.notifications_button}
                    startIcon={<HomeIcon />}
                  >
                    Dashboard
                  </PrimaryButton>
                </div>
              </div>
            )}
            <CTAButton type="submit" onClick={handleLogout}>
              Logout
            </CTAButton>
            <div className={styles.buttons}>
              {!isFalls && (
                <SecondaryButton onClick={() => navigate("/faqs")}>
                  FAQs
                </SecondaryButton>
              )}
              <SecondaryButton onClick={() => navigate("/about")}>
                About
              </SecondaryButton>
              <SecondaryButton onClick={() => navigate("/privacy")}>
                Privacy
              </SecondaryButton>
            </div>
          </>
        )}

        {editProfile && (
          <EditUserProfile
            close={() => setEditProfile(false)}
            user={user}
            onSubmitNameChange={onSubmitNameChange}
            onSubmitPasswordChange={onSubmitPasswordChange}
            onSubmitEmailChange={onSubmitEmailChange}
            onSubmitPhoneNumChange={onSubmitPhoneNumChange}
            changePasswordError={changePasswordError}
            changeNameError={changeNameError}
            changeEmailError={changeEmailError}
            changePhoneNumError={changePhoneNumError}
          />
        )}
      </div>
    </Page>
  );
}

Profile.propTypes = {
  history: PropTypes.object,
};

export default Profile;
