import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
// Reports
import CareAct from "../../Reports/CareAct";

function PdfGeneratorV2() {
  const [data, setData] = useState(null);
  const searchParams = new URLSearchParams(window.location.search);
  const hasLang = searchParams.has("lang");
  const language = hasLang && searchParams.get("lang") === "fr" ? "fr" : "en";

  // State readiness
  const hasData = data !== undefined || null;

  // Get the report data from local storage
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("report"));
    setData(data);
  }, []);

  return (
    <div id="report" className={styles.page}>
      {hasData && (
        <CareAct reportData={data} isPdf={true} language={language} />
      )}
    </div>
  );
}

export default PdfGeneratorV2;
