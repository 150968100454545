export const areArraysEqualById = (prev, next) => {
  // If lengths are different, the arrays are not equal
  if (prev.length !== next.length) {
    return false;
  }

  // Create a Set of previous IDs for quick lookup
  const prevIds = new Set(prev.map((item) => item.id));

  // Check if all IDs in the next array exist in the previous array
  for (const item of next) {
    if (!prevIds.has(item.id)) {
      return false;
    }
  }

  // No differences detected
  return true;
};
