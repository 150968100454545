import React from "react";
import theme from "../../theme.scss";

export const BathroomIcon = ({ width, colour }) => {
  return (
    <svg
      width={width || 24}
      height={width || 24}
      viewBox="0 0 24 24"
      fill="none"
      id="BathroomIcon"
    >
      <path
        d="M7 9C6.45 9 5.97917 8.80417 5.5875 8.4125C5.19583 8.02083 5 7.55 5 7C5 6.45 5.19583 5.97917 5.5875 5.5875C5.97917 5.19583 6.45 5 7 5C7.55 5 8.02083 5.19583 8.4125 5.5875C8.80417 5.97917 9 6.45 9 7C9 7.55 8.80417 8.02083 8.4125 8.4125C8.02083 8.80417 7.55 9 7 9ZM5 22C4.71667 22 4.47917 21.9042 4.2875 21.7125C4.09583 21.5208 4 21.2833 4 21C3.45 21 2.97917 20.8042 2.5875 20.4125C2.19583 20.0208 2 19.55 2 19V13H5V12.25C5 11.6167 5.21667 11.0833 5.65 10.65C6.08333 10.2167 6.61667 10 7.25 10C7.58333 10 7.89167 10.0667 8.175 10.2C8.45833 10.3333 8.71667 10.5167 8.95 10.75L10.35 12.3C10.4833 12.4333 10.6125 12.5583 10.7375 12.675C10.8625 12.7917 11 12.9 11.15 13H18V4.85C18 4.61667 17.9167 4.41667 17.75 4.25C17.5833 4.08333 17.3833 4 17.15 4C17.05 4 16.9542 4.02083 16.8625 4.0625C16.7708 4.10417 16.6833 4.16667 16.6 4.25L15.35 5.5C15.4333 5.78333 15.45 6.0625 15.4 6.3375C15.35 6.6125 15.25 6.86667 15.1 7.1L12.35 4.3C12.5833 4.15 12.8333 4.05417 13.1 4.0125C13.3667 3.97083 13.6333 4 13.9 4.1L15.15 2.85C15.4167 2.58333 15.7208 2.375 16.0625 2.225C16.4042 2.075 16.7667 2 17.15 2C17.95 2 18.625 2.275 19.175 2.825C19.725 3.375 20 4.05 20 4.85V13H22V19C22 19.55 21.8042 20.0208 21.4125 20.4125C21.0208 20.8042 20.55 21 20 21C20 21.2833 19.9042 21.5208 19.7125 21.7125C19.5208 21.9042 19.2833 22 19 22H5ZM4 19H20V15H4V19Z"
        fill={colour || theme.neutral7}
      />
    </svg>
  );
};
