import { differenceInHours, differenceInDays } from "date-fns";

const isOlderThan24Hours = (timestamp) => {
  const now = new Date();
  const fallTime = new Date(timestamp);
  const diffInHours = differenceInHours(now, fallTime);
  return diffInHours > 24;
};

export const isLessThanEqualTo7DaysOld = (timestamp) => {
  const now = new Date();
  const fallTime = new Date(timestamp);
  const diffInDays = differenceInDays(now, fallTime);
  return diffInDays <= 7;
};

export const groupFallsData = (serviceUsersData) => {
  const liveFalls = [];
  const recentFalls = [];
  const otherUsers = [];

  if (!serviceUsersData || typeof serviceUsersData !== "object") {
    return { liveFalls, recentFalls, otherUsers };
  }

  Object.entries(serviceUsersData).forEach(([hubId, userData]) => {
    if (!userData || !userData.falls || userData.falls.length === 0) {
      return;
    }

    userData.falls.forEach((fall) => {
      if (!fall || typeof fall !== "object") {
        return;
      }

      const { current_status, timestamp } = fall;

      if (current_status === "detected") {
        liveFalls.push(fall);
      } else if (
        current_status !== "detected" &&
        timestamp &&
        isOlderThan24Hours(timestamp) &&
        isLessThanEqualTo7DaysOld(timestamp)
      ) {
        recentFalls.push(fall);
      } else {
        otherUsers.push(fall);
      }
    });
  });

  return { liveFalls, recentFalls, otherUsers };
};
