import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
// State
import { useSelector } from "react-redux";
import { getOrganisation } from "@intelligentlilli/api-layer";

const FallsDashboardHeader = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const server = useSelector((state) => state.session.server);
  const [organisation, setOrganisation] = useState();

  useEffect(() => {
    if (user.organisationId) {
      getOrganisation(server, user.organisationId, "web")
        .then((res) => res.ok && setOrganisation(res.body))
        .catch((err) => {
          console.log(err);
          if (err.status === 401) {
            navigate("/login");
          }
        });
    }
  }, [server, user.organisationId, navigate]);

  return (
    <div className={styles.dashboard_header_container}>
      <div>
        <h1 className={styles.page_title}>Dashboard</h1>
        <div className={styles.user_info} data-private>
          {user?.forenames} {user?.surname} | {organisation?.name || ""}
        </div>
      </div>
    </div>
  );
};

export default FallsDashboardHeader;
