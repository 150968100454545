import React, { useRef, useEffect } from "react";
import styles from "./styles.module.scss";
import theme from "../../../Styles/theme.scss";
import PrimaryButton from "../../PrimaryButton";
import SensorSignal from "../../SensorSignal";
import InstallSensorLocation from "../../InstallSensorLocation";
import AltSpinner from "../../Spinners/AltSpinner";
import { useViewport } from "../../ViewportProvider";
import { INSTALL_INSTRUCTIONS } from "../../../Services/Constants";

const SensorCard = ({
  error,
  sensor,
  sensors,
  loading,
  onConnect,
  onViewGuide,
  showMissingLocationMessage,
  sensorLocations,
  connectionsChecked,
}) => {
  const instructions = INSTALL_INSTRUCTIONS[sensor?.label];
  const { width } = useViewport();
  const isVerySmallScreen = width <= 290;
  const sensorData = sensors?.find((s) => s?.name === sensor?.name);
  const showLocationError =
    sensor?.label !== "h1" &&
    showMissingLocationMessage &&
    !sensorLocations?.[sensor?.name] &&
    sensor.locationRequired === true;
  const showCannotConnectError =
    sensor?.label !== "h1" &&
    connectionsChecked[sensor?.label] === true &&
    !sensorData?.online &&
    !loading;

  const connectButtonLabel = sensorData?.online ? "Connected" : "Connect me";
  console.log("sensorData", sensorData);

  const videoRef = useRef();
  useEffect(() => {
    videoRef.current?.load();
  }, [sensor.video]);

  return (
    <div
      className={
        error || showCannotConnectError
          ? styles.sensor_card_error
          : styles.sensor_card_default
      }
    >
      <div className={styles.top_sensor_card}>
        <div>{instructions?.title}</div>
        <SensorSignal
          online={sensorData?.online}
          loading={sensorData?.loading}
        />
      </div>
      <div className={styles.location_instructions}>
        Location: <span>{instructions?.locationInstructions}</span>
      </div>
      <div className={styles.video_container}>
        <video
          ref={videoRef}
          disablePictureInPicture
          id={sensor.name}
          loop
          muted
          autoPlay
          controlsList="nodownload nofullscreen noremoteplayback"
          playsInline
          className={styles.video}
          src={sensor.video}
          type="video/webm"
        />
      </div>
      <InstallSensorLocation
        sensor={sensor}
        showLocationError={showLocationError}
      />
      <div className={styles.sensor_card_buttons}>
        <PrimaryButton
          onClick={() => onViewGuide(true)}
          style={{
            width: "100%",
            marginRight: "12px",
            backgroundColor: "white",
            borderRadius: "8px",
            border: `1px solid ${theme.primary3}`,
            lineHeight: 1.75,
            padding: "6px 8px",
          }}
        >
          {isVerySmallScreen ? "Guide" : " View guide"}
        </PrimaryButton>
        <PrimaryButton
          disabled={loading}
          onClick={onConnect}
          style={{ width: "100%", borderRadius: "8px" }}
          loading={loading}
          startIcon={loading && <AltSpinner small />}
        >
          {connectButtonLabel}
        </PrimaryButton>
      </div>
      {error && <div className={styles.sensor_connection_error}>{error}</div>}

      {showCannotConnectError ? (
        <div className={styles.error_container}>
          <p className={styles.error_message}>
            Connection failed. Plese try again. If the problem persists, contact
            support at{" "}
            <span className={styles.email}>support@intelligentlilli.com</span>
          </p>
        </div>
      ) : null}

      {showLocationError ? (
        <div className={styles.error_container}>
          <p className={styles.error_message}>
            Please select a location for this sensor
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default SensorCard;
