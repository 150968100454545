import React from "react";
import styles from "./styles.module.scss"; // generic form styles
import { Form, Field } from "react-final-form";
import Checkbox from "../../Components/Checkbox";
import LilliButton from "../../Components/DesignSystemComponents/LilliButton/LilliButton";

const ResolveFallForm = ({ error, onSubmit, setFalseAlarm }) => {
  return (
    <Form
      onSubmit={(values) => onSubmit(values.forename)}
      render={({ handleSubmit, submitting, pristine, valid, form }) => (
        <form
          onSubmit={(event) => {
            handleSubmit(event);
          }}
        >
          <div className={styles.section}>
            <h2 className={styles.label}>
              Cause of the fall (Select all that apply)
            </h2>
            <Field name="slipped/tripped" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Slipped/tripped</span>
                </label>
              )}
            </Field>
            <Field name="lossOfBalance" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Loss of balance</span>
                </label>
              )}
            </Field>
            <Field name="medicalEvent" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Medical event (e.g. fainting, seizure)</span>
                </label>
              )}
            </Field>
            <Field name="environmentalHazard" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>
                    Environmental hazard (e.g. wet floor, poor lighting)
                  </span>
                </label>
              )}
            </Field>
            <Field name="otherCause" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Other</span>
                </label>
              )}
            </Field>
            {/* Conditionally Rendered Text Input */}
            <Field name="otherCause" subscription={{ value: true }}>
              {({ input: { value } }) =>
                value ? (
                  <Field name="otherCauseText">
                    {({ input }) => (
                      <div>
                        <input
                          {...input}
                          type="text"
                          className={styles.input}
                          placeholder="Please specify..."
                        />
                      </div>
                    )}
                  </Field>
                ) : null
              }
            </Field>
          </div>

          <div className={styles.section}>
            <h2 className={styles.label}>
              Outcome of the fall (Select all that apply)
            </h2>
            <Field name="noInjury" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>No injury / Stable condition</span>
                </label>
              )}
            </Field>
            <Field name="bruising" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Bruising / Minor injury</span>
                </label>
              )}
            </Field>
            <Field name="pain" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Pain or discomfort (no serious injury)</span>
                </label>
              )}
            </Field>
            <Field name="observationRequired" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Observation required (monitoring for changes)</span>
                </label>
              )}
            </Field>
            <Field name="ambulanceRequired" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Ambulance Called (potential serious injury)</span>
                </label>
              )}
            </Field>
            <Field name="doctorContacted" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Doctor to be contacted</span>
                </label>
              )}
            </Field>
            <Field name="familyContacted" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Family to be contacted</span>
                </label>
              )}
            </Field>
            <Field name="otherOutcome" type="checkbox">
              {({ input }) => (
                <label className={styles.field_check}>
                  <Checkbox input={input} />
                  <span>Other</span>
                </label>
              )}
            </Field>
            {/* Conditionally Rendered Text Input */}
            <Field name="otherOutcome" subscription={{ value: true }}>
              {({ input: { value } }) =>
                value ? (
                  <Field name="otherOutcomeText">
                    {({ input }) => (
                      <div>
                        <input
                          {...input}
                          type="text"
                          className={styles.input}
                          placeholder="Please specify..."
                        />
                      </div>
                    )}
                  </Field>
                ) : null
              }
            </Field>
          </div>
          <div>
            <Field name="summary" type="freeText" initialValue={""}>
              {({ input }) => {
                return (
                  <>
                    <h2 className={styles.text_area_label}>
                      Summary of the incident (Briefly describe what happened)
                    </h2>
                    <textarea
                      data-private
                      name="monitoring"
                      placeholder="Please specify..."
                      maxLength={601}
                      className={styles.text_area}
                      {...input}
                    />
                  </>
                );
              }}
            </Field>
          </div>
          <div className={styles.buttons}>
            <LilliButton
              variant="dangerSecondary"
              onClick={() => setFalseAlarm(true)}
            >
              False alarm
            </LilliButton>
            <div className={styles.buttons_right}>
              <LilliButton variant="secondary">Cancel</LilliButton>
              <LilliButton>Submit action</LilliButton>
            </div>
          </div>
        </form>
      )}
    ></Form>
  );
};

export default ResolveFallForm;
